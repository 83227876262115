<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div class="stepped-post-creation-form-container">
        <ul v-if="Object.values(errors).some(Boolean)" class="error-list my-4">
            <li v-if="errors.noText">
                <router-link to="#content" class="error-link" @click.native="focusOn('description')">
                    {{ $t('steppedPostCreation.errors.noText') }}
                    &rarr;
                </router-link>
            </li>

            <li v-if="errors.noDate || errors.noTime">
                <router-link to="#context" class="error-link" @click.native="focusOn(errors.noDate ? 'date' : 'time')">
                    {{ $t('steppedPostCreation.errors.noDateOrTime') }}
                    &rarr;
                </router-link>
            </li>

            <li v-if="errors.noLocation">
                <router-link to="#context" class="error-link" @click.native="focusOn('location')">
                    {{ $t('steppedPostCreation.errors.noLocation') }}
                    &rarr;
                </router-link>
            </li>

            <li v-else-if="errors.noCoordinates">
                <router-link to="#context" class="error-link" @click.native="focusOn('location')">
                    {{ $t('steppedPostCreation.errors.noCoordinates') }}
                    &rarr;
                </router-link>
            </li>
        </ul>

        <ol class="step-dots">
            <li v-for="(step, i) in steps" :key="step">
                <router-link
                    :to="`#${step}`"
                    class="step-dot"
                    :data-prior="i < currentStepIndex"
                    :data-active="i === currentStepIndex"
                    :aria-label="$t(`steppedPostCreation.${step}.heading`)"
                >
                    <span>•</span>
                </router-link>
            </li>
        </ol>

        <form ref="form" data-test-id="Post creation form">
            <template v-if="currentStep === 'content'">
                <h2 class="step-heading text-overline" tabindex="-1" data-test-id="Step heading for content">
                    {{ $t('steppedPostCreation.content.heading') }}
                </h2>

                <div class="step-input mt-0">
                    <v-row align="center">
                        <v-col v-if="currentUser" cols="auto">
                            <user-avatar :user="currentUser" image-only />
                        </v-col>

                        <v-col class="description-input-label">{{ $t('steppedPostCreation.content.description.label') }}</v-col>
                    </v-row>

                    <div class="description-input-container">
                        <textarea
                            ref="description"
                            v-model="post.textBody"
                            :placeholder="String($t('steppedPostCreation.content.description.placeholder'))"
                            class="description-input"
                            data-test-id="Description field"
                        />

                        <div class="unusual-toggles-container">
                            <span class="unusual-toggles-label">
                                {{ $t('steppedPostCreation.content.unusual.label') }}
                            </span>

                            <v-btn-toggle v-model="post.weird" mandatory rounded color="white">
                                <v-btn :value="true" small :color="post.weird ? 'primary' : null">
                                    {{ $t('steppedPostCreation.content.unusual.yes') }}
                                </v-btn>

                                <v-btn :value="false" small :color="!post.weird ? 'primary' : null">
                                    {{ $t('steppedPostCreation.content.unusual.no') }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </div>
                </div>

                <div class="step-input">
                    <image-preview-list v-if="post.photos.length > 0" v-model="post.photos" class="mb-4" />

                    <div style="display: flex; flex-wrap: wrap; gap: var(--spacing-2);">
                        <image-input icon="photo_camera" :max-file-size="2 ** 25" @input="post.photos = [...post.photos, ...$event]" />
                        <image-input icon="video_library" :max-video-duration="120" :max-file-size="2 ** 25" @input="post.photos = [...post.photos, ...$event]" />
                    </div>

                    <p class="input-note mt-4">
                        <v-icon style="color: inherit;">play_circle_outline</v-icon>
                        {{ $t('steppedPostCreation.content.videoHint') }}
                    </p>
                </div>
            </template>

            <template v-if="currentStep === 'context'">
                <h2 class="step-heading text-overline" tabindex="-1" data-test-id="Step heading for context">
                    {{ $t('steppedPostCreation.context.heading') }}
                </h2>

                <v-alert
                    :value="true"
                    color="success"
                    border="left"
                    colored-border
                    :elevation="2"
                    class="mt-8"
                >
                    <div class="ml-2">{{ $t('steppedPostCreation.context.note') }}</div>
                </v-alert>

                <div class="step-input">
                    <v-row>
                        <v-col :cols="6">
                            <base-date-picker
                                ref="date"
                                v-model="post.date"
                                required
                                :label="$t('steppedPostCreation.context.date.label')"
                                max="now"
                                hide-details
                                data-test-id="Date field"
                            />
                        </v-col>

                        <v-col :cols="6">
                            <base-date-picker
                                ref="time"
                                v-model="post.time"
                                required
                                type="time"
                                :label="$t('steppedPostCreation.context.time.label')"
                                hide-details
                                data-test-id="Time field"
                            />
                        </v-col>
                    </v-row>

                    <div v-if="daysSinceTimestamp > 10 || timestampMatchesPhoto" class="mt-n2">
                        <div v-if="typeof daysSinceTimestamp === 'number' && daysSinceTimestamp > 10" class="input-note">
                            <v-icon style="color: inherit;">history</v-icon>
                            This was {{ daysSinceTimestamp.toLocaleString() }} days ago.
                        </div>

                        <div v-if="timestampMatchesPhoto" class="input-note">
                            <v-icon style="color: inherit;">event_available</v-icon>
                            This timestamp is from your photo.
                        </div>
                    </div>
                </div>

                <div class="step-input">
                    <two-step-address-selector
                        ref="location"
                        :location.sync="post.location"
                        :location-hint="$t('steppedPostCreation.context.location.obscured')"
                        :coordinates.sync="post.coordinates"
                    >
                        <template #coordinatesSubLabel>
                            {{ $t('steppedPostCreation.context.location.accuracy') }}
                        </template>

                        <template #in-between>
                            <div v-if="locationMatchesPhoto" class="input-note">
                                <v-icon style="color: inherit;">where_to_vote</v-icon>
                                This location is from your photo.
                            </div>
                        </template>
                    </two-step-address-selector>
                </div>
            </template>

            <template v-if="currentStep === 'tags'">
                <h2 class="step-heading text-overline mb-8" tabindex="-1" data-test-id="Step heading for tags">
                    {{ $t('steppedPostCreation.tags.heading') }}
                </h2>

                <div v-if="fetchingSuggestedTags !== 0" class="loading-indicator-container">
                    <loading-indicator />
                </div>

                <template v-else>
                    <v-alert
                        :value="true"
                        color="success"
                        border="left"
                        colored-border
                        :elevation="2"
                    >
                        <div class="ml-2">
                            <strong>{{ $t(`steppedPostCreation.tags.${ nlpResults.length === 0 ? 'noSuggestionsNote' : 'suggestionsNote'}.0`) }}</strong>
                            <template v-for="(line, i) in Object.assign([], $t(`steppedPostCreation.tags.${ nlpResults.length === 0 ? 'noSuggestionsNote' : 'suggestionsNote'}`)).slice(1)">
                                <br :key="i">
                                {{ line }}
                            </template>
                        </div>
                    </v-alert>

                    <div v-for="[category, categoryInvestigations] in investigationsByCategory" :key="category" class="my-8">
                        <h3 v-if="category !== '$NONE'">{{ category }}</h3>

                        <div class="tag-buttons">
                            <base-button
                                v-for="investigation in categoryInvestigations"
                                :key="investigation.id"
                                :outlined="!post.investigationIds.includes(investigation.id)"
                                :color="post.investigationIds.includes(investigation.id) ? 'success' : undefined"
                                :data-test-id="`Tag for ${investigation.slug}`"
                                @click="toggleInvestigation(investigation)"
                            >
                                <v-icon v-if="post.investigationIds.includes(investigation.id)" left>check</v-icon>
                                <span class="tag-button-label">{{ investigation.name.split(' / ')[0] }}</span>
                            </base-button>
                        </div>
                    </div>
                </template>
            </template>

            <template v-if="currentStep === 'questions'">
                <h2 class="step-heading text-overline mb-8" tabindex="-1" data-test-id="Step heading for questions">
                    {{ $t('steppedPostCreation.questions.heading.0') }}
                    <span class="optional">{{ $t('steppedPostCreation.questions.heading.1') }}</span>
                </h2>

                <template v-for="(questions, investigationId) in deduplicatedQuestions">
                    <div :key="investigationId" data-test-id="Structured questions" style="margin: 0 auto; max-width: 50ch;">
                        <!-- <h3>{{ investigations.find(i => i.id === investigationId).name }}</h3> -->
                        <post-details-form-partial
                            :investigation="investigations.find(i => i.id === investigationId)"
                            :structured-questions="questions"
                            :answers="post.structuredAnswers"
                        />
                    </div>
                </template>
            </template>

            <action-footer class="text-center">
                <base-button text @click="$emit('reset')">
                    {{ $t('steppedPostCreation.reset.button') }}
                </base-button>

                <base-button
                    color="primary"
                    :loading="saving"
                    :disabled="saving"
                    :data-test-id="currentStepIndex < steps.length - 1 ? 'Continue button' : 'Submit button'"
                    @click="goToNextStep"
                >
                    <template v-if="currentStepIndex < steps.length - 1">
                        <v-icon left>arrow_forward</v-icon>
                        {{ $t('steppedPostCreation.next') }}
                    </template>
                    <template v-else>
                        <v-icon left>check</v-icon>
                        {{ $t('steppedPostCreation.submit') }}
                    </template>
                    <template #loader>
                        <v-progress-circular :value="saveProgress * 100" :indeterminate="saveProgress < 0.1" :rotate="-90" :size="20" />
                    </template>
                </base-button>
                <div v-if="secondsRemaining || saveProgress > 0.5">
                    <small>{{ $tc('steppedPostCreation.timeRemaining', Math.floor(secondsRemaining ?? 0), { remaining: Math.floor(secondsRemaining ?? 0) }) }}</small>
                </div>
                <div v-else-if="totalFileSize > 500000">
                    <small>{{ $tc('steppedPostCreation.slowUpload', post.photos.length, { size: `${(totalFileSize / 1000 / 1000).toFixed(1)} MB` }) }}</small>
                </div>
            </action-footer>
        </form>

        <v-dialog v-if="blocker" :value="true" max-width="35ch" @input="(open = false) => { if (!open) blocker = null }">
            <v-card>
                <v-card-title>{{ $t(`steppedPostCreation.errors.${blocker}`) }}</v-card-title>

                <v-card-actions>
                    <v-spacer />
                    <base-button text @click="blocker = null">{{ $t('actions.ok') }}</base-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-mutating-props */

import { CurrentUser, DraftPost, Investigation, StructuredQuestion } from '@/types';
import Vue from '@/vueTyped';
import ActionFooter from '@/components/ActionFooter.vue';
import ImageInput from '@/components/ImageInput.vue';
import ImagePreviewList from '@/components/ImagePreviewList.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import PostDetailsFormPartial from '@/components/web/PostDetailsFormPartial.vue';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { NLP_API_HOST } from '@/config';
import orderBy from 'lodash/orderBy';
import { timePostCreation, trackPostFormStepEnter, trackSuggestedTagRemoval, trackPostFormStepExit } from '@/tracking';
import BaseDatePicker from './BaseDatePicker.vue';
import { PhotoMetadata } from '@/pages/PostsCreate/index.vue';
import moment from 'moment';

type NlpApiResult = { slug: string };

export default Vue.extend({
    name: 'SteppedPostCreationForm',

    components: {
        ActionFooter,
        ImageInput,
        ImagePreviewList,
        LoadingIndicator,
        PostDetailsFormPartial,
        TwoStepAddressSelector,
        UserAvatar,
        BaseDatePicker,
    },

    props: {
        investigations: {
            type: Array as () => Investigation[],
            default: () => [],
        },

        post: {
            type: Object as () => DraftPost,
            default: () => ({} as DraftPost),
        },

        photoMetadata: {
            type: Object as () => PhotoMetadata,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({} as { [key: string]: string }),
        },

        saving: {
            type: Boolean,
            default: false,
        },

        saveProgress: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            console,
            blocker: null as string | null,
            fetchingSuggestedTags: 0,
            textCheckedByNlp: false,
            nlpResults: [] as NlpApiResult[],
            saveStarted: 0,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        steps(): string[] {
            const steps = ['content', 'context', 'tags'];

            if (Object.values(this.deduplicatedQuestions).flat().length !== 0) {
                steps.push('questions');
            }

            return steps;
        },

        currentStep(): string {
            const hash = this.$route.hash.slice(1);
            return this.steps.includes(hash) ? hash : this.steps[0];
        },

        currentStepIndex(): number {
            return this.steps.indexOf(this.currentStep);
        },

        timestampMatchesPhoto(): boolean {
            return Boolean(
                this.post.date &&
                this.post.time &&
                this.post.date === this.photoMetadata.date &&
                this.post.time === this.photoMetadata.time
            );
        },

        daysSinceTimestamp(): number | null {
            if (this.post.date && this.post.time) {
                return moment().diff(`${this.post.date}T${this.post.time}`, 'days');
            } else {
                return null;
            }
        },

        locationMatchesPhoto(): boolean {
            return Boolean(
                this.post.coordinates &&
                this.photoMetadata.coordinates &&
                this.post.coordinates[0] === this.photoMetadata.coordinates[0] &&
                this.post.coordinates[1] === this.photoMetadata.coordinates[1]
            );
        },

        investigationsByCategory(): [string, Investigation[]][] {
            const results: { [id: string]: Investigation[] } = {};

            for (const investigation of this.investigations) {
                const category = investigation.investigationCategory || '$NONE';

                if (results[category] === undefined) {
                    results[category] = [];
                }

                results[category].push(investigation);
            }

            return orderBy(Object.entries(results), '0');
        },

        selectedInvestigations(): Investigation[] {
            return this.investigations.filter(investigation => this.post.investigationIds.includes(investigation.id));
        },

        deduplicatedQuestions(): { [investigationId: string]: StructuredQuestion[] } {
            const askedQuestions = new Set();
            const questionsByInvestigationId: { [investigationId: string]: StructuredQuestion[] } = {};

            for (const investigation of this.selectedInvestigations) {
                for (const question of investigation.structuredQuestions) {
                    if (question.published) {
                        if (!askedQuestions.has(question.title)) {
                            if (questionsByInvestigationId[investigation.id] === undefined) {
                                questionsByInvestigationId[investigation.id] = [];
                            }

                            questionsByInvestigationId[investigation.id].push(question);
                            askedQuestions.add(question.title);
                        }
                    }
                }
            }

            Object.entries(questionsByInvestigationId).forEach(([investigationId, questions]) => {
                questionsByInvestigationId[investigationId] = orderBy(questions, 'order');
            });

            return questionsByInvestigationId;
        },

        totalFileSize() {
            return this.post.photos.reduce((total, photo) => {
                return total + (photo.size ?? 0);
            }, 0);
        },

        secondsRemaining(): number | null {
            this.saveProgress; // Reference this up front to mark it as a dependency.

            const elapsed = Date.now() - this.saveStarted;

            if (this.saveStarted === 0 || elapsed < 1000) {
                return null;
            }

            const percentPerMs = this.saveProgress / elapsed;
            return (1 - this.saveProgress) / (percentPerMs * 1000);
        }
    },

    watch: {
        currentStep: {
            immediate: true,
            handler(currentStep, previousStep) {
                if (previousStep) {
                    trackPostFormStepExit(previousStep);

                    // Another branch I have open right now changes "basics" to "content",
                    // and if I don't put both here I know I'll break it without realizing.
                    // TODO: Remove "basics" check when that change is merged in.
                    if (previousStep === 'basics' || previousStep === 'content') {
                        if (!this.textCheckedByNlp) {
                            this.fetchSuggestedTags(this.post.textBody);
                        }
                    }
                }

                trackPostFormStepEnter(currentStep);
            },
        },

        'post.textBody'() {
            this.textCheckedByNlp = false;
        },

        'post.investigationIds'(currentIds: string[]) {
            const currentSlugs = currentIds.map(id => this.investigations.find(i => i.id === id)?.slug);

            const removed = this.nlpResults.filter(result => !currentSlugs.includes(result.slug));

            if (removed.length !== 0) {
                for (const result of removed) {
                    trackSuggestedTagRemoval(result.slug);
                }
            }
        },

        saveProgress(saveProgress, saveProgressWas) {
            if (saveProgressWas === 0) {
                this.saveStarted = Date.now();
            }
        },
    },

    mounted() {
        timePostCreation();
    },

    methods: {
        async fetchSuggestedTags(text: string) {
            try {
                this.fetchingSuggestedTags += 1;

                const nlpRequestBody = new FormData();
                nlpRequestBody.set('text', text);

                const nlpResponse = await fetch(`${NLP_API_HOST}/predict-topics`, {
                    method: 'POST',
                    body: nlpRequestBody,
                });

                const nlpResults: NlpApiResult[] = await nlpResponse.json();
                this.nlpResults = nlpResults.filter(result => this.investigations.find(investigation => investigation.slug === result.slug));

                for (const result of this.nlpResults) {
                    const investigation = this.investigations.find(investigation => investigation.slug === result.slug);
                    if (investigation && !this.post.investigationIds.includes(investigation.id)) {
                        this.post.investigationIds.push(investigation.id);
                    }
                }

                this.textCheckedByNlp = true;
            } catch (ignoredError) {
                // This is not critical to functionality.
            } finally {
                this.fetchingSuggestedTags -= 1;
            }
        },

        toggleInvestigation(investigation: Investigation) {
            const index = this.post.investigationIds.indexOf(investigation.id);
            if (index === -1) {
                this.post.investigationIds.push(investigation.id);
            } else {
                this.post.investigationIds.splice(index, 1);
            }
        },

        goToNextStep() {
            if (this.currentStep === 'content') {
                if (!this.post.textBody) {
                    this.blocker = 'noText';
                }
            } else if (this.currentStep === 'context') {
                if (!this.post.date || !this.post.time) {
                    this.blocker = 'noDateOrTime';
                } else if (!this.post.location) {
                    this.blocker = 'noLocation';
                } else if (!this.post.coordinates) {
                    this.blocker = 'noCoordinates';
                }
            }

            if (this.blocker) {
                return;
            }

            const nextStep = this.steps[this.currentStepIndex + 1];
            if (nextStep) {
                this.$router.push(`#${nextStep}`);
                this.resetFocus();
            } else {
                this.$emit('submit');
            }
        },

        async resetFocus() {
            await this.$nextTick();
            const heading: HTMLHeadingElement | null = (this.$refs.form as HTMLFormElement).querySelector('[tabindex="-1"]');
            heading?.focus();
            heading?.blur();
        },

        async focusOn(refName: string) {
            await this.$nextTick();
            const target = this.$refs[refName] as any;
            if (target.focus instanceof Function) {
                target.focus();
            } else if (target.$el instanceof HTMLElement) {
                target.$el.querySelector('input, textarea')?.focus();
            }
        }
    },
});
</script>

<style lang="postcss" scoped>
:deep(.v-btn:focus:hover::before) {
    opacity: 0 !important; /* Avoid disabled-looking grayed-out hover state. */
}

.stepped-post-creation-form-container {
    margin: 0 auto;
    max-width: 80ch;
    padding: var(--spacing-4) var(--spacing-4) 0;
}

.step-dots {
    display: flex;
    justify-content: center;
    padding: 0;
}

.step-dots > li {
    display: block;
}

.step-dot {
    color: var(--color-medium);
    display: block;
    font-size: 2em;
    line-height: 0.5;
    padding: 0.2em;
    text-decoration: none;
}

.step-dot[data-prior],
.step-dot[data-active] {
    color: var(--color-warning);
}

.error-list {
    background: var(--color-danger);
    border-radius: 5px;
    padding: 0;
    position: sticky;
    top: 1em;
    z-index: 1;
}

.error-list > li {
    display: block;
}

.error-list > li:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.error-link {
    color: #fff;
    display: block;
    font-weight: bold;
    padding: 1ch 2ch;
    text-decoration: none;
}

.step-heading {
    margin-bottom: 1em;
    text-align: center;
}

.step-heading .optional {
    font-weight: normal;
    text-transform: none;
}

.step-input {
    margin: var(--spacing-2) 0 var(--spacing-6);
}

.description-input-label {
    font-size: 1.3em;
}

.description-input-container {
    border: 1px solid var(--color-medium);
    border-radius: 4px;
}

.description-input {
    height: 10em;
    padding: 1em;
    width: 100%;
}

.unusual-toggles-container {
    background: var(--color-light);
    border-radius: 0 0 5px 5px;
    padding: 0.5em 1em;
}

.unusual-toggles-label {
    display: inline-block;
    margin-right: 1em;
}

.input-note {
    color: #398500;
}

.loading-indicator-container {
    height: 30ch;
    position: relative;
}

.tag-buttons {
    margin: -0.7ch;
}

.tag-buttons > * {
    margin: 0.7ch;
}

.tag-button-label {
    text-transform: none;
}
</style>
