<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div class="stepped-post-creation-form-container">
        <ul v-if="Object.values(errors).some(Boolean)" class="error-list my-4">
            <li v-if="errors.noText">
                <router-link to="#content" class="error-link" @click.native="focusOn('description')">
                    {{ $t('steppedPostCreation.errors.noText') }}
                    &rarr;
                </router-link>
            </li>

            <li v-if="errors.noDate || errors.noTime">
                <router-link to="#context" class="error-link" @click.native="focusOn(errors.noDate ? 'date' : 'time')">
                    {{ $t('steppedPostCreation.errors.noDateOrTime') }}
                    &rarr;
                </router-link>
            </li>

            <li v-if="errors.noLocation">
                <router-link to="#context" class="error-link" @click.native="focusOn('location')">
                    {{ $t('steppedPostCreation.errors.noLocation') }}
                    &rarr;
                </router-link>
            </li>

            <li v-else-if="errors.noCoordinates">
                <router-link to="#context" class="error-link" @click.native="focusOn('location')">
                    {{ $t('steppedPostCreation.errors.noCoordinates') }}
                    &rarr;
                </router-link>
            </li>
        </ul>

        <ol class="step-dots">
            <li v-for="(step, i) in steps" :key="step">
                <router-link
                    :to="`#${step}`"
                    class="step-dot"
                    :data-prior="i < currentStepIndex"
                    :data-active="i === currentStepIndex"
                    :aria-label="$t(`steppedPostCreation.${step}.heading`)"
                >
                    {{ '•' }}
                </router-link>
            </li>
        </ol>

        <form ref="form" :class="{ small: $vuetify.breakpoint.xs }">
            <div v-if="currentStep === 'content'">
                <div class="description-input-label">
                    <v-icon>image_search</v-icon>
                    {{ $t('steppedPostCreation.content.descriptionWithTweaks.label') }}
                </div>

                <textarea
                    ref="description"
                    v-model="post.textBody"
                    :placeholder="String($t('steppedPostCreation.content.descriptionWithTweaks.placeholder'))"
                    class="description-input"
                />

                <div class="step-input">
                    <image-preview-list v-if="post.photos.length > 0" v-model="post.photos" class="mb-4" />

                        <div class="stretch-across">
                        <div>
                            <image-input dark large block icon="photo_camera" :max-file-size="2 ** 25" @input="post.photos = [...post.photos, ...$event]" />
                        </div>
                        <div>
                            <image-input dark large block icon="video_library" :max-video-duration="120" :max-file-size="2 ** 25" @input="post.photos = [...post.photos, ...$event]" />
                        </div>
                    </div>

                    <p class="input-note">
                        {{ $t('steppedPostCreation.content.videoHint') }}
                    </p>
                </div>
            </div>

            <div v-if="currentStep === 'context'">
                <v-alert
                    :value="true"
                    color="success"
                    border="left"
                    colored-border
                    :elevation="2"
                    class="mt-8"
                >
                    <div class="ml-2">{{ $t('steppedPostCreation.context.note') }}</div>
                </v-alert>

                <div class="step-input">
                    <v-row>
                        <v-col :cols="6">
                            <base-date-picker
                                ref="date"
                                v-model="post.date"
                                required
                                :label="$t('steppedPostCreation.context.date.label')"
                                max="now"
                                hide-details
                                data-test-id="Date field"
                            />
                        </v-col>

                        <v-col :cols="6">
                            <base-date-picker
                                ref="time"
                                v-model="post.time"
                                required
                                type="time"
                                :label="$t('steppedPostCreation.context.time.label')"
                                hide-details
                                data-test-id="Time field"
                            />
                        </v-col>
                    </v-row>

                    <div v-if="daysSinceTimestamp > 10 || timestampMatchesPhoto" class="mt-n2">
                        <span v-if="typeof daysSinceTimestamp === 'number' && daysSinceTimestamp > 10" class="input-note">
                            <v-icon style="color: inherit;">history</v-icon>
                            This was {{ daysSinceTimestamp.toLocaleString() }} days ago.
                        </span>

                        <span v-if="timestampMatchesPhoto" class="input-note">
                            <v-icon style="color: inherit;">event_available</v-icon>
                            This timestamp is from your photo.
                        </span>
                    </div>
                </div>

                <div class="step-input">
                    <two-step-address-selector
                        ref="location"
                        :location.sync="post.location"
                        :location-hint="$t('steppedPostCreation.context.location.obscured')"
                        :coordinates.sync="post.coordinates"
                    >
                        <template #coordinatesSubLabel>
                            {{ $t('steppedPostCreation.context.location.accuracy') }}
                        </template>

                        <template #in-between>
                            <div v-if="locationMatchesPhoto" class="input-note">
                                <v-icon style="color: inherit;">where_to_vote</v-icon>
                                This location is from your photo.
                            </div>
                        </template>
                    </two-step-address-selector>
                </div>
            </div>

            <div v-if="currentStep === 'tags'">
                <div v-if="fetchingSuggestedTags !== 0" class="loading-indicator-container">
                    <loading-indicator />
                </div>

                <template v-else>
                    <v-alert
                        :value="true"
                        color="success"
                        border="left"
                        colored-border
                        :elevation="2"
                    >
                        <div class="ml-2">
                            <strong>{{ $t(`steppedPostCreation.tags.${ nlpResults.length === 0 ? 'noSuggestionsNote' : 'suggestionsNote'}.0`) }}</strong>
                            <template v-for="(line, i) in Object.assign([], $t(`steppedPostCreation.tags.${ nlpResults.length === 0 ? 'noSuggestionsNote' : 'suggestionsNote'}`)).slice(1)">
                                <br :key="i">
                                {{ line }}
                            </template>
                        </div>
                    </v-alert>

                    <div v-for="[category, categoryInvestigations] in investigationsByCategory" :key="category" class="my-8">
                        <h3 v-if="category !== '$NONE'">{{ category }}</h3>

                        <div class="tag-buttons">
                            <base-button
                                v-for="investigation in categoryInvestigations"
                                :key="investigation.id"
                                :outlined="!post.investigationIds.includes(investigation.id)"
                                :color="post.investigationIds.includes(investigation.id) ? 'success' : undefined"
                                :data-test-id="`Tag for ${investigation.slug}`"
                                @click="toggleInvestigation(investigation)"
                            >
                                <v-icon v-if="post.investigationIds.includes(investigation.id)" left>check</v-icon>
                                <span class="tag-button-label">{{ investigation.name.split(' / ')[0] }}</span>
                            </base-button>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="currentStep === 'questions'">
                <template v-for="(questions, investigationId) in deduplicatedQuestions">
                    <div :key="investigationId" data-test-id="Structured questions" style="margin: 0 auto; max-width: 50ch;">
                        <!-- <h3>{{ investigations.find(i => i.id === investigationId).name }}</h3> -->
                        <post-details-form-partial
                            :investigation="investigations.find(i => i.id === investigationId)"
                            :structured-questions="questions"
                            :answers="post.structuredAnswers"
                        />
                    </div>
                </template>
            </div>

            <div class="controls" :class="{ small: $vuetify.breakpoint.xs }">
                <div class="stretch-across">
                    <div style="text-align: center;">
                        <base-button large text @click="$emit('reset')">
                            {{ $t('steppedPostCreation.reset.button') }}
                        </base-button>
                    </div>

                    <div>
                        <base-button
                            large
                            color="primary"
                            block
                            :loading="saving"
                            :disabled="saving"
                            :data-test-id="currentStepIndex < steps.length - 1 ? 'Continue button' : 'Submit button'"
                            @click="goToNextStep"
                        >
                            <template v-if="currentStepIndex < steps.length - 1">
                                {{ $t('steppedPostCreation.next') }}
                            </template>
                            <template v-else>
                                <v-icon left>check</v-icon>
                                {{ $t('steppedPostCreation.submit') }}
                            </template>
                            <template #loader>
                                <v-progress-circular :value="saveProgress * 100" :indeterminate="saveProgress < 0.1" :rotate="-90" :size="20" />
                            </template>
                        </base-button>
                    </div>
                </div>

                <div v-if="typeof secondsRemaining === 'number' && (secondsRemaining || saveProgress > 0.5)">
                    <small>{{ $tc('steppedPostCreation.timeRemaining', Math.floor(secondsRemaining), { remaining: Math.floor(secondsRemaining) }) }}</small>
                </div>

                <div v-else-if="totalFileSize > 500000" class="input-note">
                    {{ $tc('steppedPostCreation.slowUpload', post.photos.length, { size: `${(totalFileSize / 1000 / 1000).toFixed(1)} MB` }) }}
                </div>
            </div>
        </form>

        <v-dialog v-if="blocker" :value="true" max-width="35ch" @input="(open = false) => { if (!open) blocker = null }">
            <v-card>
                <v-card-title>{{ $t(`steppedPostCreation.errors.${blocker}`) }}</v-card-title>

                <v-card-actions>
                    <v-spacer />
                    <base-button text @click="blocker = null">{{ $t('actions.ok') }}</base-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-mutating-props */

import { CurrentUser, DraftPost, Investigation, StructuredQuestion } from '@/types';
import Vue from '@/vueTyped';
import ImageInput from '@/components/ImageInput.vue';
import ImagePreviewList from '@/components/ImagePreviewList.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import PostDetailsFormPartial from '@/components/web/PostDetailsFormPartial.vue';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import { NLP_API_HOST } from '@/config';
import orderBy from 'lodash/orderBy';
import { timePostCreation, trackPostFormStepEnter, trackSuggestedTagRemoval, trackPostFormStepExit } from '@/tracking';
import BaseDatePicker from './BaseDatePicker.vue';
import { PhotoMetadata } from '@/pages/PostsCreate/index.vue';
import moment from 'moment';

type NlpApiResult = { slug: string };

export default Vue.extend({
    name: 'SteppedPostCreationForm',

    components: {
        ImageInput,
        ImagePreviewList,
        LoadingIndicator,
        PostDetailsFormPartial,
        TwoStepAddressSelector,
        BaseDatePicker,
    },

    props: {
        investigations: {
            type: Array as () => Investigation[],
            default: () => [],
        },

        post: {
            type: Object as () => DraftPost,
            default: () => ({} as DraftPost),
        },

        photoMetadata: {
            type: Object as () => PhotoMetadata,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({} as { [key: string]: string }),
        },

        saving: {
            type: Boolean,
            default: false,
        },

        saveProgress: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            console,
            blocker: null as string | null,
            fetchingSuggestedTags: 0,
            textCheckedByNlp: false,
            nlpResults: [] as NlpApiResult[],
            saveStarted: 0,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        steps(): string[] {
            const steps = ['content', 'context', 'tags'];

            if (Object.values(this.deduplicatedQuestions).flat().length !== 0) {
                steps.push('questions');
            }

            return steps;
        },

        currentStep(): string {
            const hash = this.$route.hash.slice(1);
            return this.steps.includes(hash) ? hash : this.steps[0];
        },

        currentStepIndex(): number {
            return this.steps.indexOf(this.currentStep);
        },

        timestampMatchesPhoto(): boolean {
            return Boolean(
                this.post.date &&
                this.post.time &&
                this.post.date === this.photoMetadata.date &&
                this.post.time === this.photoMetadata.time
            );
        },

        daysSinceTimestamp(): number | null {
            if (this.post.date && this.post.time) {
                return moment().diff(`${this.post.date}T${this.post.time}`, 'days');
            } else {
                return null;
            }
        },

        locationMatchesPhoto(): boolean {
            return Boolean(
                this.post.coordinates &&
                this.photoMetadata.coordinates &&
                this.post.coordinates[0] === this.photoMetadata.coordinates[0] &&
                this.post.coordinates[1] === this.photoMetadata.coordinates[1]
            );
        },

        investigationsByCategory(): [string, Investigation[]][] {
            const results: { [id: string]: Investigation[] } = {};

            for (const investigation of this.investigations) {
                const category = investigation.investigationCategory || '$NONE';

                if (results[category] === undefined) {
                    results[category] = [];
                }

                results[category].push(investigation);
            }

            return orderBy(Object.entries(results), '0');
        },

        selectedInvestigations(): Investigation[] {
            return this.investigations.filter(investigation => this.post.investigationIds.includes(investigation.id));
        },

        deduplicatedQuestions(): { [investigationId: string]: StructuredQuestion[] } {
            const askedQuestions = new Set();
            const questionsByInvestigationId: { [investigationId: string]: StructuredQuestion[] } = {};

            for (const investigation of this.selectedInvestigations) {
                for (const question of investigation.structuredQuestions) {
                    if (question.published) {
                        if (!askedQuestions.has(question.title)) {
                            if (questionsByInvestigationId[investigation.id] === undefined) {
                                questionsByInvestigationId[investigation.id] = [];
                            }

                            questionsByInvestigationId[investigation.id].push(question);
                            askedQuestions.add(question.title);
                        }
                    }
                }
            }

            Object.entries(questionsByInvestigationId).forEach(([investigationId, questions]) => {
                questionsByInvestigationId[investigationId] = orderBy(questions, 'order');
            });

            return questionsByInvestigationId;
        },

        totalFileSize() {
            return this.post.photos.reduce((total, photo) => {
                return total + (photo.size ?? 0);
            }, 0);
        },

        secondsRemaining(): number | null {
            this.saveProgress; // Reference this up front to mark it as a dependency.

            const elapsed = Date.now() - this.saveStarted;

            if (this.saveStarted === 0 || elapsed < 1000) {
                return null;
            }

            const percentPerMs = this.saveProgress / elapsed;
            return (1 - this.saveProgress) / (percentPerMs * 1000);
        }
    },

    watch: {
        currentStep: {
            immediate: true,
            handler(currentStep, previousStep) {
                if (previousStep) {
                    trackPostFormStepExit(previousStep);

                    // Another branch I have open right now changes "basics" to "content",
                    // and if I don't put both here I know I'll break it without realizing.
                    // TODO: Remove "basics" check when that change is merged in.
                    if (previousStep === 'basics' || previousStep === 'content') {
                        if (!this.textCheckedByNlp) {
                            this.fetchSuggestedTags(this.post.textBody);
                        }
                    }
                }

                trackPostFormStepEnter(currentStep);
            },
        },

        'post.textBody'() {
            this.textCheckedByNlp = false;
        },

        'post.investigationIds'(currentIds: string[]) {
            const currentSlugs = currentIds.map(id => this.investigations.find(i => i.id === id)?.slug);

            const removed = this.nlpResults.filter(result => !currentSlugs.includes(result.slug));

            if (removed.length !== 0) {
                for (const result of removed) {
                    trackSuggestedTagRemoval(result.slug);
                }
            }
        },

        saveProgress(saveProgress, saveProgressWas) {
            if (saveProgressWas === 0) {
                this.saveStarted = Date.now();
            }
        },
    },

    mounted() {
        timePostCreation();
    },

    methods: {
        async fetchSuggestedTags(text: string) {
            try {
                this.fetchingSuggestedTags += 1;

                const nlpRequestBody = new FormData();
                nlpRequestBody.set('text', text);

                const nlpResponse = await fetch(`${NLP_API_HOST}/predict-topics`, {
                    method: 'POST',
                    body: nlpRequestBody,
                });

                const nlpResults: NlpApiResult[] = await nlpResponse.json();
                this.nlpResults = nlpResults.filter(result => this.investigations.find(investigation => investigation.slug === result.slug));

                for (const result of this.nlpResults) {
                    const investigation = this.investigations.find(investigation => investigation.slug === result.slug);
                    if (investigation && !this.post.investigationIds.includes(investigation.id)) {
                        this.post.investigationIds.push(investigation.id);
                    }
                }

                this.textCheckedByNlp = true;
            } catch (ignoredError) {
                // This is not critical to functionality.
            } finally {
                this.fetchingSuggestedTags -= 1;
            }
        },

        toggleInvestigation(investigation: Investigation) {
            const index = this.post.investigationIds.indexOf(investigation.id);
            if (index === -1) {
                this.post.investigationIds.push(investigation.id);
            } else {
                this.post.investigationIds.splice(index, 1);
            }
        },

        goToNextStep() {
            if (this.currentStep === 'content') {
                if (!this.post.textBody) {
                    this.blocker = 'noText';
                }
            } else if (this.currentStep === 'context') {
                if (!this.post.date || !this.post.time) {
                    this.blocker = 'noDateOrTime';
                } else if (!this.post.location) {
                    this.blocker = 'noLocation';
                } else if (!this.post.coordinates) {
                    this.blocker = 'noCoordinates';
                }
            }

            if (this.blocker) {
                return;
            }

            const nextStep = this.steps[this.currentStepIndex + 1];
            if (nextStep) {
                this.$router.push(`#${nextStep}`);
                this.resetFocus();
            } else {
                this.$emit('submit');
            }
        },

        async resetFocus() {
            await this.$nextTick();
            const heading: HTMLHeadingElement | null = (this.$refs.form as HTMLFormElement).querySelector('[tabindex="-1"]');
            heading?.focus();
            heading?.blur();
        },

        async focusOn(refName: string) {
            await this.$nextTick();
            const target = this.$refs[refName] as any;
            if (target.focus instanceof Function) {
                target.focus();
            } else if (target.$el instanceof HTMLElement) {
                target.$el.querySelector('input, textarea')?.focus();
            }
        }
    },
});
</script>

<style>
:has(> * > .stepped-post-creation-form-container) {
    display: flex;
    flex-direction: column;
}
</style>

<style scoped>
:deep(.v-btn:focus:hover::before) {
    opacity: 0 !important; /* Avoid disabled-looking grayed-out hover state. */
}

.stepped-post-creation-form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 auto;
    max-width: 90vw;
    width: 80ch;
}

.stepped-post-creation-form-container > form {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.stepped-post-creation-form-container > form.small {
    justify-content: space-between;
}

.controls {
    margin-block-end: 20px;
}

.controls:not(.small) {
    margin-block-start: 30px;
}

.stretch-across {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-4);
}

.stretch-across > * {
    flex: 1;
}

.step-dots {
    display: flex;
    justify-content: center;
}

.step-dots > li {
    display: block;
}

.step-dot {
    border-radius: 50%;
    color: var(--color-primary);
    display: grid;
    font-size: 24px;
    height: 40px;
    margin: 12px 0;
    place-content: center;
    text-decoration: none;
    width: 40px;
}

.step-dot:not([data-active], [data-prior]) {
    opacity: 0.5;
}

.error-list {
    background: var(--color-danger);
    border-radius: 5px;
    padding: 0;
    position: sticky;
    top: 1em;
    z-index: 1;
}

.error-list > li {
    display: block;
}

.error-list > li:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.error-link {
    color: #fff;
    display: block;
    font-weight: bold;
    padding: 1ch 2ch;
    text-decoration: none;
}

.step-input {
    margin: var(--spacing-2) 0 var(--spacing-6);
}

.description-input-label {
    color: var(--color-royal);
    font-size: calc(22rem / 16);
    margin-block-end: 16px;
}

.description-input-label i {
    color: var(--color-primary);
}

.description-input {
    border: 1px solid #0003;
    border-radius: 16px;
    height: 10em;
    margin-block-end: 8px;
    padding: 16px;
    width: 100%;
}

.input-note {
    color: var(--color-medium-shade);
    font-size: var(--type-small);
    margin-block-start: 16px;
    text-align: center;
}

.input-note i {
    color: inherit;
}

.loading-indicator-container {
    height: 30ch;
    position: relative;
}

.tag-buttons {
    margin: -0.7ch;
}

.tag-buttons > * {
    margin: 0.7ch;
}

.tag-button-label {
    text-transform: none;
}
</style>
